<template>
  <v-row v-if="unrestrictedGiftLink">
    <v-col cols="12" class="recipients-area__title">
      Enter recipients manually
    </v-col>

    <v-col cols="12">
      <cg-alert type="information" background-color="#FCEBA4">
        This product can be shipped to US recipients only!
      </cg-alert>
    </v-col>

    <v-col cols="12" class="mb-4">
      <common-checkbox v-model="unrestrictedGiftLink">
        Unrestricted gift link (specify only number of recipients)

        <unrestricted-gift-link-tooltip />
      </common-checkbox>

      <v-divider class="mt-8" v-if="!unrestrictedGiftLink" />
    </v-col>

    <v-col cols="12">
      <the-unrestricted-gift-link
        v-bind="_props"
        v-on="$listeners"
      />
    </v-col>
  </v-row>


  <v-form ref="recipientsForm" @submit.prevent="goToPreviewEGift()" v-else>
    <v-row>
      <v-col cols="12" class="recipients-area__title">
        Enter recipients manually
      </v-col>

      <v-col cols="12">
        <cg-alert type="information" background-color="#FCEBA4">
          This product can be shipped to US recipients only!
        </cg-alert>
      </v-col>

      <v-col cols="12" class="mb-4" v-if="panelType === 'link'">
        <common-checkbox v-model="unrestrictedGiftLink">
          Unrestricted gift link (specify only number of recipients)

          <unrestricted-gift-link-tooltip />
        </common-checkbox>

        <v-divider class="mt-8" />
      </v-col>

      <v-col cols="12">
        <template v-for="(recipient, index) in manualRecipients">
          <v-row :key="index">
            <v-col :cols="panelType === 'link' ? 4 : 3">
              <label :for="`recipient-first-name-${index}`">
                First name
              </label>
              <common-input
                v-model="recipient.first_name"
                :rules="[v => !!v || '']"
                :id="`recipient-first-name-${index}`"
                placeholder="First name"
                height="36"
              />
            </v-col>
            <v-col :cols="panelType === 'link' ? 4 : 3">
              <label :for="`recipient-last-name-${index}`">
                Last name
              </label>
              <common-input
                v-model="recipient.last_name"
                :id="`recipient-last-name-${index}`"
                placeholder="Last name"
                height="36"
              />
            </v-col>
            <v-col :cols="panelType === 'link' ? 4 : 3">
              <label :for="`recipient-email-${index}`">
                Email {{ panelType === 'link' ? '(optional)' : null }}
              </label>

              <div class="d-flex align-center justify-space-between">
                <common-input
                  v-model="recipient.email"
                  :rules="
                    panelType === 'egift'
                    ? [ v => !!v || '', v => /.+@.+\..+/.test(v) ]
                    : []"
                  :error="isEmailDuplicated(recipient.email)"
                  :id="`recipient-email-${index}`"
                  placeholder="Email"
                  height="36"
                />

                <div
                  class="d-flex pointer ml-4"
                  @click="deleteRecipient(index)"
                  v-if="manualRecipients.length > 1 && panelType === 'link'"
                >
                  <icons-delete :width="14" :height="17" />
                </div>
              </div>
            </v-col>
            <v-col cols="3" v-if="panelType !== 'link'">
              <label :for="`recipient-send-date-${index}`">
                Send date
              </label>
              <div class="d-flex align-center justify-space-between">
                <single-date-range-picker
                  v-model="recipient.send_date"
                  :id="`recipient-send-date-${index}`"
                  @change="(val) => handleSendDateChange(val)"
                  :disabled="marketplaceUser"
                  picker-style="--min-width: 160px;--picker-margin-top: -1px;--picker-left: 343px"
                  showCheckbox
                  :checkboxValue.sync="setSendDateForAllRecipients"
                />

                <div
                  class="d-flex pointer"
                  @click="deleteRecipient(index)"
                  v-if="manualRecipients.length > 1"
                >
                  <icons-delete :width="14" :height="17" />
                </div>
              </div>
            </v-col>
          </v-row>
        </template>
      </v-col>

      <v-col cols="12">
        <green-text-with-plus @onClick="addNewRecipient()">
          Add another recipient
        </green-text-with-plus>
      </v-col>

      <v-col cols="12">
        <v-divider class="my-4" />
      </v-col>

      <v-col cols="12">
        <common-button
          height="45"
          style="width: 280px"
          @click="goToPreviewEGift()"
        >
          Continue to review e-gift
        </common-button>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import GreenTextWithPlus from '@/components/myCampaign/common/GreenTextWithPlus.vue'
import SingleDateRangePicker from '@/components/myCampaign/common/SingleDateRangePicker.vue'
import TheUnrestrictedGiftLink from './TheRecipientsAreaUnrestrictedGiftLink.vue'
import UnrestrictedGiftLinkTooltip from '../TheUnrestrictedGiftLinkCheckboxTooltip.vue'
import { CgAlert } from '@corporategift/design-system'

import panelType from '@/components/myCampaign/panels/mixins/panelType'
import marketplaceUser from '@/components/myCampaign/panels/mixins/marketplaceUser'
import takeCurrentDate from '@/components/myCampaign/panels/mixins/takeCurrentDate'

export default {
  name: 'TheRecipientsAreaSelectManually',
  components: {
    CgAlert,
    GreenTextWithPlus,
    SingleDateRangePicker,
    TheUnrestrictedGiftLink,
    UnrestrictedGiftLinkTooltip,
  },
  mixins: [
    panelType,
    marketplaceUser,
    takeCurrentDate,
  ],
  props: {
    linkType: {
      type: String,
      required: false,
      default: null
    },
    numberOfRecipients: {
      type: Number,
      required: false,
      default: null
    },
  },
  data: () => ({
    setSendDateForAllRecipients: false,
    sendDateForNewRecipient: null,
    manualRecipients: []
  }),
  computed: {
    unrestrictedGiftLink: {
      get () {
        return this.linkType === 'single_link_unrestricted'
      },
      set (val) {
        this.$emit('update:linkType', val ? 'single_link_unrestricted' : 'standard_link')
      },
    },
    isEveryEmailUnique () {
      const { manualRecipients } = this
      const emails = manualRecipients?.map((recipient) => recipient.email).filter((email) => email) ?? []

      return new Set(emails).size === emails.length
    }
  },
  watch: {
    manualRecipients: {
      deep: true,
      handler (recipients) {
        const userHasChanges = recipients.map((recipient) => {
          if (recipient.email || recipient.first_name || recipient.last_name) return true
          return false
        })
        this.$emit('setUnsavedChanges', userHasChanges.includes(true))
      }
    },
    setSendDateForAllRecipients: function (val) {
      if (!val) this.sendDateForNewRecipient = null
    }
  },
  created () {
    this.addNewRecipient()
  },
  methods: {
    addNewRecipient () {
      this.manualRecipients.push({
        first_name: '',
        last_name: '',
        email: '',
        send_date: this.sendDateForNewRecipient || this.takeCurrentDate()
      })
    },
    deleteRecipient (indexToRemove) {
      this.manualRecipients = this.manualRecipients?.filter((item, index) => index !== indexToRemove) || []
    },
    handleSendDateChange (sendDate) {
      const { setSendDateForAllRecipients, manualRecipients } = this
      if (setSendDateForAllRecipients) {
        this.sendDateForNewRecipient = sendDate
        manualRecipients.forEach((recipient) => {
          recipient.send_date = sendDate
        })
      }
    },
    goToPreviewEGift () {
      if (this.$refs.recipientsForm.validate() && this.isEveryEmailUnique) {
        this.$emit('showPreview', this.manualRecipients)
      }
    },
    isEmailDuplicated (emailToCheck) {
      const { manualRecipients } = this
      if (!emailToCheck) return false

      const emails = manualRecipients?.map((recipient) => recipient.email) ?? []
      return emails.filter((email) => email === emailToCheck).length !== 1
    }
  }
}
</script>

<style lang="scss" scoped>
label {
  font-weight: 700;
  font-size: 15px;
  line-height: 22px;
  color: #000;
}
</style>
