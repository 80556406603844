<template>
  <v-card outlined tile class="inventory-kit" min-height="430" width="350">
    <div class="inventory-kit__images">
      <v-row no-gutters class="h100">
        <template v-if="item.product.kit.products.length === 2">
          <template v-for="(product, index) in item.product.kit.products">
            <v-col
              cols="6"
              v-if="index === 1"
              :key="`empty-${index}`"
            />

            <v-col
              cols="6"
              :key="index"
              v-if="index < 3 || (item.product.kit.products.length <= 4 && index <= 4)"
            >
              <v-img
                :alt="product.name"
                :src="getImagePath(product.image)"
                width="148"
                height="148"
                class="mx-auto"
              />
            </v-col>

            <v-col
              cols="6"
              v-if="index === 0"
              :key="`empty-${index}`"
            />
          </template>
        </template>

        <template v-else>
          <template v-for="(product, index) in item.product.kit.products">
            <v-col
              cols="6"
              :key="index"
              v-if="index < 3 || (item.product.kit.products.length <= 4 && index <= 4)"
            >
              <v-img
                :alt="product.name"
                :src="getImagePath(product.image)"
                width="148"
                height="148"
                class="mx-auto"
              />
            </v-col>
          </template>

          <v-col
            cols="6"
            class="d-flex"
            v-if="item.product.kit.products.length > 4"
          >
            <div class="inventory-kit__multiple-images">
              +{{ item.product.kit.products.length - 3 }}
            </div>
          </v-col>
        </template>
      </v-row>

      <tray>
        <div class="d-flex">
          <tray-badge
            v-if="showBalanceBadge"
            color="red"
            outlined
            v-tooltip="{
              content: tooltipText,
              ...iconTooltipProps,
              offset: 8,
              placement: 'top',
              classes: 'inventory-status__icon-tooltip inventory-status__icon-tooltip--dark',
            }"
          >
            {{ userKitBalance | maxZeroFilter }}
          </tray-badge>
        </div>

        <tray-kit-options
          v-if="superAdmin || masterAccount"
          @onOrdersHistory="showOrdersHistory()"
          @onAssignUsers="showAssignUsers()"
          @onShowKitPanel="showKitPanel()"
        />

        <div
          v-else
          class="inventory-kit__refill-icon"
          @click="showKitPanel()"
          v-tooltip="{ content: 'Kit items', ...iconTooltipProps }"
        >
          <icons-settings-line :width="20" :height="20" />
        </div>
      </tray>
    </div>

    <v-card-text class="px-6 pt-4 pb-0 mt-auto">
      <a v-if="linkToKitPage" class="inventory-kit__name" :href="linkToKitPage" target="_blank">
        {{ item.product.name }}
      </a>

      <h1 v-else class="inventory-kit__name">
        {{ item.product.name }}
      </h1>
    </v-card-text>

    <v-card-actions class="pa-6">
      <common-button
        v-if="isGroupedKit"
        :height="44"
        :disabled="disableButton"
        block
        @click="sendItemAsEGift(item)"
      >
        Send with eGift
      </common-button>

      <inventory-card-send-button v-else :item="item" />
    </v-card-actions>
  </v-card>
</template>

<script>
import superAdmin from '@/mixins/superAdmin'
import masterAccount from '@/mixins/masterAccount'
import maxZeroFilter from '@/mixins/maxZeroFilter'
import iconTooltipProps from './inventoryStatus/mixins/iconTooltipProps'
import genInventoryProductUrl from './utils/genInventoryProductUrl'
import sendInventoryActions from './inventoryStatus/mixins/sendInventoryActions'

import Tray from './InventoryCardTray/Tray'
import TrayBadge from './InventoryCardTray/TrayBadge'
import TrayKitOptions from './InventoryCardTray/TrayKitOptions'
import InventoryCardSendButton from './InventoryCardSendButton.vue'

export default {
  name: 'InventoryKitCard',
  mixins: [
    superAdmin,
    masterAccount,
    maxZeroFilter,
    sendInventoryActions,
    iconTooltipProps,
  ],
  components: {
    Tray,
    TrayBadge,
    TrayKitOptions,
    InventoryCardSendButton,
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    kitType () {
      return this.item?.product?.kit?.type
    },
    isGroupedKit () {
      return this.kitType === 'grouped'
    },
    userKitBalance () {
      return this.item?.product?.balance ?? null
    },
    balancePeriod () {
      return this.item?.product?.period ?? null
    },
    showBalanceBadge () {
      const { superAdmin, masterAccount, userKitBalance } = this

      return !superAdmin && !masterAccount && userKitBalance !== null
    },
    tooltipText () {
      const { userKitBalance: balance, balancePeriod } = this
      return `Balance: ${balance} units ${balancePeriod}`
    },
    linkToKitPage () {
      const { item, isGroupedKit } = this
      const productUrl = item?.product?.product_url ?? null

      if (isGroupedKit) { return null }
      return genInventoryProductUrl(productUrl)
    },
    productsMinQty () {
      const productsQty = this.item?.product?.kit?.products?.map((item) => (item.qty)) ?? []
      return Math.min(...productsQty)
    },
    disableButton () {
      const { item, productsMinQty } = this
      const flag = item?.product?.kit?.flags?.disable_when_child_product_out_of_stock ?? false

      if (flag) { return productsMinQty < 1 }
      return false
    },
  },
  methods: {
    getProductId () {
      const { product: { product_id: productId } } = this.item
      return productId
    },
    showKitPanel () {
      this.$emit('showKitPanel', this.item)
    },
    showOrdersHistory () {
      const productId = this.getProductId()
      const { name: productName } = this.item?.product ?? {}
      // TODO add one action
      this.$store.commit('transactions-history/setSearch', productName);
      this.$store.commit('transactions-history/setFiltersProductId', productId);
      this.$store.commit('transactions-history/setFiltersTypes');
      this.$store.commit('transactions-history/setFiltersCampaignId');
      this.$store.commit('transactions-history/setFiltersUserId');
      this.$store.commit('transactions-history/setFiltersDateRange', { start: null, end: null });
      this.$store.commit('transactions-history/setFiltersOrderId');

      // move to transactions page
      const query = Object.assign({}, this.$route.query)
      query.tab = 'transactions'
      this.$router.replace({ query }).catch(() => {})
    },
    showAssignUsers () {
      const productId = this.getProductId()
      this.$emit('showAssignUsersPanel', productId)
    },
    getImagePath (image) {
      return image.includes('no_selection') ? '/images/empty-order.png' : image
    },
  }
}
</script>

<style lang="scss" scoped>
.inventory-kit {
  display: flex;
  flex-direction: column;

  &__images {
    position: relative;
    height: 325px;
    padding: 32px 24px 8px;
  }

  &__refill-icon {
    height: 38px;
    width: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 28px;
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
    transition: background-color ease-in-out 0.2s;

    &:hover {
      background-color: #F4F4F4;
    }
  }

  &__name {
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    font-size: 15px;
    color: #222325 !important;

    &:is(a) {
      cursor: pointer;
      letter-spacing: 0;
      display: inline-block;

      &:hover {
        color: #219358 !important;
      }
    }
  }

  &__multiple-images {
    width: 126px;
    height: 126px;
    background-color: #DFF3F2;
    border-radius: 50%;
    margin: auto;

    display: grid;
    place-items: center;

    font-weight: 400;
    font-size: 45px;
    color: #16181f;
  }

  .v-card__actions {
    button {
      font-size: 18px;
      letter-spacing: 0;
      text-transform: unset;
      font-weight: 600 !important;
    }
  }
}
</style>
