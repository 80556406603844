
import { Vue, Component, Prop } from 'vue-property-decorator'
import type { ISavedLogo } from '../../types'

import getRecaptchaToken from '@/utils/getRecaptchaToken'

import Api from '@/axios/api'

@Component
export default class GreetingUploadLogo extends Vue {
  @Prop({ required: false, default: null }) readonly value!: string | null;

  @Prop({ required: false, default: false }) readonly onPageHelper!: boolean;

  loadingLogo = false;

  get selectedFile (): string | null {
    return this.value
  }

  set selectedFile (val: string | null) {
    this.$emit('input', val)
  }

    onChange () {
      const files = (this.$refs.file as HTMLInputElement).files
      const file = files?.[0] || null
      
      if (file) { this.uploadFile(file) }
    }

    dragover (event: DragEvent) {
      event.preventDefault()

      if (event.currentTarget) {
        const currentTarget = event.currentTarget as HTMLElement

        if (!currentTarget.classList.contains('greeting-upload-logo--file-hover')) {
          currentTarget.classList.add('greeting-upload-logo--file-hover')
        }
      }
    }

    dragleave (event: DragEvent) {
      if (event.currentTarget) {
        const currentTarget = event.currentTarget as HTMLElement
        currentTarget.classList.remove('greeting-upload-logo--file-hover')
      }
    }

    drop (event: DragEvent): void {
      event.preventDefault();

      if (event?.dataTransfer?.files) {
        const currentTarget = event.currentTarget as HTMLElement

        (this.$refs.file as HTMLInputElement).files = event?.dataTransfer?.files
        this.onChange()
        currentTarget.classList.remove('greeting-upload-logo--file-hover')
      }
    }

    inputFocus (): void {
      const ele = document.querySelector('.greeting-upload-logo')
      ele?.classList.add('greeting-upload-logo--file-hover')
    }

    inputBlur () {
      const ele = document.querySelector('.greeting-upload-logo')
      ele?.classList.remove('greeting-upload-logo--file-hover')
    }

    removeFile (event: MouseEvent): void {
      if (this.selectedFile) {
        this.selectedFile = null;
        (this.$refs.file as HTMLInputElement).value = ''
        event.preventDefault()
      }
    }

    handleTooltip (event: MouseEvent) {
      const tooltip: HTMLDivElement | null = this.$el.querySelector('.greeting-upload-logo__tooltip')

      if (tooltip) {
        const image = this.$el.querySelector('label')
        const imageWidth = image?.offsetWidth ?? 0
        const tooltipWidth = tooltip.offsetWidth ?? 0
        const pageScrollTop = window.pageYOffset
        const imageLeft = image?.getBoundingClientRect()?.left ?? 0
        const { pageX, clientY } = event

        if (this.onPageHelper) {
          tooltip.style.left = `${pageX - ((tooltipWidth - 5) / 2)}px`
          tooltip.style.top = `${clientY + pageScrollTop + 28}px`
        } else {
          // panel left padding - 8 = label padding
          tooltip.style.left = `${pageX - (imageLeft - 42 - imageWidth / 2)}px`
          tooltip.style.top = `${clientY + 28}px`
        }
      }
    }

    uploadFile (file: File) {
      const formData = new FormData()
      formData.append('file', file, file?.name)

      const userIsLoggedIn = false

      if (userIsLoggedIn) {
        this.uploadLogoForLoggedInUser(formData)
      } else {
        this.uploadLogoForNotLoggedInUser(formData)
      }
    }

    uploadLogoForLoggedInUser (formData: FormData) {
      this.loadingLogo = true

      Api.post('/customer/logos', formData)
        .then(({ success, data }: { success?: boolean; data: ISavedLogo }) => {
          if (success) {
            this.selectedFile = data.url ?? null
            this.$store.dispatch('snackbar/showSnackbar',
              { message: 'Logo uploaded successfully', type: 'success' }
            )
          }
        })
        .catch((e) => {
          if (e?.response?.data) {
            const { file } = e?.response?.data?.errors
            const errorMessage = file?.join(' ') || 'The logo failed to upload.'

            this.$store.dispatch('snackbar/showSnackbar',
              { message: errorMessage, type: 'error' }
            )
          } else {
            this.$store.dispatch('snackbar/showSnackbar',
              { message: 'The logo failed to upload.', type: 'error' }
            )
          }
        })
        .finally(() => (this.loadingLogo = false))
    }

    async uploadLogoForNotLoggedInUser (formData: FormData) {
      this.loadingLogo = true

      try {
        const recaptchaToken: string = await getRecaptchaToken()

        formData.append('recaptcha_token', recaptchaToken)

        Api.post('/shared/logos', formData)
          .then(({ success, data }: { success?: boolean; data: ISavedLogo }) => {
            if (success) {
              this.selectedFile = data.url ?? null
              this.$store.dispatch('snackbar/showSnackbar',
                { message: 'Logo uploaded successfully', type: 'success' }
              )
            }
          })
          .catch((e) => {
            if (e?.response?.data) {
              const { file } = e?.response?.data?.errors
              const errorMessage = file?.join(' ') || 'The logo failed to upload.'

              this.$store.dispatch('snackbar/showSnackbar',
                { message: errorMessage, type: 'error' }
              )
            } else {
              this.$store.dispatch('snackbar/showSnackbar',
                { message: 'The logo failed to upload.', type: 'error' }
              )
            }
          })
          .finally(() => (this.loadingLogo = false))
      } catch (error) {
        this.loadingLogo = false
      }
    }
}
