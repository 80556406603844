import { render, staticRenderFns } from "./SendEgiftPanelSuccess.vue?vue&type=template&id=4fa829ce&scoped=true&"
import script from "./SendEgiftPanelSuccess.vue?vue&type=script&lang=ts&"
export * from "./SendEgiftPanelSuccess.vue?vue&type=script&lang=ts&"
import style0 from "./SendEgiftPanelSuccess.vue?vue&type=style&index=0&id=4fa829ce&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fa829ce",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
installComponents(component, {VDivider})
