<template>
  <v-row no-gutters class="greeting-message">
    <v-col cols="12" class="d-flex align-center justify-space-between pb-3">
      <span class="greeting-message__label">
        Greeting:

        <popper
          :options="{
            placement: 'top',
            modifiers: { offset: { offset: '0, 5px' } },
          }"
        >
          <div class="popper pa-4 text-left" style="max-width: unset">
            <img
              alt=""
              width="500"
              height="350"
              :src="getProductImageBySlug('campaigns_greeting_preview')"
            />
          </div>
          <div slot="reference" class="d-flex">
            <icons-question width="24" height="24" class="pointer" />
          </div>
        </popper>
      </span>

      <div
        class="d-flex greeting-area__link"
        @click="$emit('showGreetingIdeas')"
        v-if="!isPageInIframe"
      >
        <icons-greetings class="mr-2" />
        Greeting ideas
      </div>
    </v-col>
    <v-col cols="12" class="greeting-area">
      <div class="greeting-area__content">
        <v-row no-gutters>
          <v-col cols="12">
            <v-textarea
              v-model="greetingMessage"
              id="greeting-message"
              ref="greeting"
              hide-details
              class="pa-0 pb-4 ma-0 v-text-field--without-border greeting-textarea"
              auto-grow
              rows="8"
              @click="setTextAreaSelection()"
              @change="setTextAreaSelection()"
            />
          </v-col>
          <v-col cols="12" class="d-flex justify-center">
            <v-img
              v-if="!loadingLogo"
              :src="photoPath"
              contain
              alt="client logo"
              width="114"
              max-width="114"
              min-width="114"
              height="114"
              max-height="114"
              min-height="114"
            >
              <template v-if="!photoPath">
                <div class="greeting-area__content__logo">
                  Logo
                </div>
              </template>
            </v-img>

            <common-loader v-else style="height: 114px; width: 114px;" />
          </v-col>
        </v-row>
      </div>
      <div class="greeting-area__actions">
        <div class="d-flex align-center justify-space-between" v-if="!isPageInIframe">
          <div
            class="d-flex align-center greeting-area__link"
            v-if="photoPath === defaultLogo"
          >
            <icons-logo class="mr-3" />

            Change logo

            <v-file-input
              v-model="greetingLogo"
              class="pointer pa-0 ma-0"
              accept="image/png, image/jpg, image/jpeg"
              dense
              prepend-icon=""
              :clearable="false"
              hide-details
            />
          </div>

          <div
            class="d-flex align-center greeting-area__link"
            @click="resetGreetingLogo"
            v-else
          >
            <icons-logo class="mr-3" />

            Restore default logo
          </div>

          <div class="d-flex align-center justify-space-between">
            <div
              class="d-flex greeting-area__link mr-3"
              @click="showMentionMenu()"
              slot="reference"
            >
              @
            </div>
          </div>
        </div>
      </div>
    </v-col>

    <the-mention-menu
      textAreaId="greeting-message"
      v-if="!isPageInIframe"
      @changeInput="(val) => updateGreetingMessage(val)"
    />

    <v-col cols="12">
      <p class="greeting-message__info mt-3 mb-0">
        Recipient will see this message when opening the e-gift notification
      </p>
    </v-col>
  </v-row>
</template>

<script>
import Api from '@/axios/api'

import Popper from 'vue-popperjs'
import TheMentionMenu from './TheMentionMenu.vue'

import isPageInIframe from '../mixins/isPageInIframe'
import getProductImageBySlug from '@/mixins/getProductImageBySlug'

export default {
  name: 'GreetingMessage',
  components: {
    Popper,
    TheMentionMenu,
  },
  mixins: [
    isPageInIframe,
    getProductImageBySlug,
  ],
  props: {
    message: {
      type: String,
      required: false,
      default: null
    },
    logo: {
      type: [String, File],
      required: false,
      default: null
    },
    previewLogo: {
      type: [String, File],
      required: false,
      default: null
    },
    defaultLogo: {
      type: [String, File],
      required: false,
      default: null
    }
  },
  data: () => ({
    photoPath: null,
    loadingLogo: false,
    textareaCursorStart: -1
  }),
  computed: {
    greetingMessage: {
      get () {
        return this.message
      },
      set (val) {
        this.$emit('update:message', val)
      }
    },
    greetingLogo: {
      get () {
        return this.logo
      },
      set (val) {
        const fileReader = new FileReader()
        fileReader.readAsDataURL(val)
        fileReader.addEventListener('load', () => {
          const { result } = fileReader
          this.photoPath = result
        })

        if (val) {
          const formData = new FormData()
          formData.append('logo', val, val?.name)
          this.loadingLogo = true
          Api.post('/campaigns/send-egift/set-tmp-picture', formData)
            .then(({ data }) => {
              this.$emit('update:previewLogo', data?.url || null)
            })
            .catch(({ response: { data } }) => {
              const message = data?.message || 'The logo failed to upload.'
              this.$cgToast.error(message)
              this.resetGreetingLogo()
            })
            .finally(() => (this.loadingLogo = false))
        }
        this.$emit('update:logo', val)
      }
    }
  },
  created () {
    this.photoPath = this.defaultLogo
  },
  methods: {
    calculateTextareaHeight () {
      setTimeout(() => {
        const greetingMessage = document.querySelector('#greeting-message')
        const scrollHeight = greetingMessage?.scrollHeight
        if (greetingMessage) greetingMessage.style.height = `${scrollHeight}px`
      }, 0)
    },
    resetGreetingLogo () {
      this.photoPath = this.defaultLogo
      this.$emit('update:logo', null)
      this.$emit('update:previewLogo', null)
    },
    showMentionMenu () {
      const { textareaCursorStart, greetingMessage } = this
      let newMessage = ''
      if (textareaCursorStart !== -1) {
        var firstPart = greetingMessage.slice(0, textareaCursorStart)
        firstPart += ' @'
        const secondPart = greetingMessage.slice(textareaCursorStart)
        newMessage = firstPart + secondPart
      } else {
        newMessage = greetingMessage + ' @'
      }
      this.greetingMessage = newMessage
      setTimeout(() => {
        if (textareaCursorStart !== -1) {
          document.querySelector('#greeting-message').setSelectionRange(textareaCursorStart + 2, textareaCursorStart + 2)
        }
        this.$refs.greeting.focus()
        document.dispatchEvent(new Event('show-mention-menu'))
      }, 0)
    },
    updateGreetingMessage (val) {
      this.greetingMessage = val
    },
    setTextAreaSelection () {
      const ele = document.querySelector('#greeting-message')
      this.textareaCursorStart = ele.selectionStart
    }
  }
}
</script>
<style lang="scss">
.greeting-message {
  position: relative;

  &__label {
    font-weight: 700;
    font-size: 15px;
    line-height: 22px;
    color: #000;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 12px;
  }

  .greeting-area {
    width: 100%;
    min-height: 400px;
    border: 1px solid #d3d2d2;
    border-radius: 8px;
    box-sizing: border-box;

    &__link {
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      color: #219358;
      cursor: pointer;
    }

    &__content {
      height: calc(100% - 38px);
      padding: 20px 35px;

      &__logo {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        min-width: 114px;
        height: 100%;
        min-height: 114px;
        background-color: #D8D8D8;
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        color: #fff;
        text-transform: uppercase;
      }
    }

    &__actions {
      position: relative;
      height: 38px;
      background-color: #F6F6F6;
      border-bottom-left-radius: inherit;
      border-bottom-right-radius: inherit;
      padding: 10px;

      & .v-input {
        opacity: 0;
        position: absolute;
        width: 110px;
        height: 16px;

        & .v-input__control {
          cursor: pointer;
          width: inherit;
          height: inherit;
          min-height: unset !important;

          & .v-input__slot {
            cursor: pointer;
            width: inherit;
            height: inherit;
            min-height: unset !important;
          }
        }
      }
    }
  }

  &__info {
    font-size: 15px !important;
    text-align: left;
    line-height: 18px;
    font-weight: 400;
    font-style: italic;
  }
}

.greeting-textarea {
  & textarea {
    font-weight: 400;
    font-style: italic;
    font-size: 22px;
    line-height: 27px;
    text-align: center;
    color: #16181f !important;
    overflow-x: hidden;
    padding: 0 2px;
  }

  & .v-input__slot:before,
  .v-input__slot:after {
    content: none !important;
  }
}

.v-text-field--without-border {
  .v-input__control .v-input__slot {
    border: 0;
  }
}
</style>
