import { render, staticRenderFns } from "./CreateCampaignReviewCardAdvancedOptions.vue?vue&type=template&id=21a00b17&scoped=true&"
import script from "./CreateCampaignReviewCardAdvancedOptions.vue?vue&type=script&lang=js&"
export * from "./CreateCampaignReviewCardAdvancedOptions.vue?vue&type=script&lang=js&"
import style0 from "./CreateCampaignReviewCardAdvancedOptions.vue?vue&type=style&index=0&id=21a00b17&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21a00b17",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VExpandTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VExpandTransition})
